import moment from 'moment';

import { Component, OnDestroy, OnInit, ViewChild, AfterViewInit, ElementRef, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { cloneDeep } from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SelectItem } from 'primeng/api';
import { Table } from 'primeng/table';

import {
    AssigneOGIVEnum,
    DemandeClient,
    GroupeEcartEnum,
    Operation,
    StatutOgivEnum,
    TypeEngagementEnum,
    WorkCenterEnum
} from '../../../../shared/models/database/demande-client.model';
import { DemandesClientSearch, LoadDemandeClientById } from '../../../store/actions/demandes-client.actions';
import { getAllDemandesClient, getDemandesClientLoading } from '../../../store/selectors/demandes-client.selectors';
import { TableColumns } from '../../../../shared/models/table-columns.model';
import { AppConfiguration, getLayerValue } from '../../../../shared/config/app-config.model';
import { DemandesClientHelper } from '../../../../shared/helpers/demandes-client.helper';
import { DemandesClientFormComponent } from '../demandes-client-form/demandes-client-form.component';
import { DemandesClientBatchUpdatesComponent } from '../demandes-client-batch-updates/demandes-client-batch-updates.component';
import { DemandesClientBatchUpdatesResults } from '../../../../shared/models/demande-client-batch-updates-results';
import { getDomaineDeValeurLoading, getNoteDeVisiteOptions } from '../../../../core/store/selectors';
import { LoadDomaineDeValeur } from '../../../../core/store/actions';
import { DemandesClientRemarqueDialogComponent } from '../demandes-client-remarque-dialog/demandes-client-remarque-dialog.component';
import { DemandeClientRemarqueDialogDataModel } from '../demandes-client-remarque-dialog/demande-client-remarque-dialog-data.model';
import { DemandesClientOperationComponent } from '../demande-client-operation/demande-client-operation.component';
import { DemandesClientOperationData } from '../demande-client-operation/demande-client-operation-data.model';

@Component({
    selector: 'app-demandes-client-list',
    templateUrl: './demandes-client-list.component.html',
    styleUrls: ['./demandes-client-list.component.scss'],
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        class: 'demandesClientListComponent',
    }
})
export class DemandesClientListComponent implements OnInit, OnDestroy, AfterViewInit {

    private subscriptions: Subscription[] = [];
    private operationsPanelEvent: PointerEvent | null;
    private readonly domaineDeValeurConcern = 'DemandesClients';
    private readonly domaineDeValeurType = 'NoteDeVisite';

    public tableFiltersItems: any = {};
    public calendarDateFormat = 'yy/mm/dd';
    public dateFormat: string = 'yyyy/MM/dd';
    public filtersEntries: any = {};
    public sortOrder = -1;
    public sortField = 'ordre.numeroOrdre';
    public currentData: DemandeClient[];
    public selectedDemandes: DemandeClient[] = [];
    public currentDemande: DemandeClient | undefined;
    public translatePrefix = 'demandes-client.list.';
    public commonTranslatePrefix = 'demandes-client.common.';
    public filtersApply: boolean;
    public currentFilters: string[] = [];
    public globalFilterFields: string[] = [];

    public cols: TableColumns[] = [
        // --- frozen columns
        { field: 'checkbox', header: '', width: '30px', frozen: true },
        { field: 'pole', header: this.translate.instant(this.commonTranslatePrefix + 'Pole'), width: '156px', frozen: true, filterType: 'multi-select' },
        { field: 'site', header: this.translate.instant(this.commonTranslatePrefix + 'Site'), width: '136px', frozen: true, filterType: 'multi-select' },
        { field: 'statutOGIV', header: this.translate.instant(this.commonTranslatePrefix + 'StatutOGIV'), width: '191px', frozen: true, filterType: 'multi-select' },
        { field: 'ordre.numeroOrdre', header: this.translate.instant(this.commonTranslatePrefix + 'NumeroOrdre'), width: '141px', frozen: true, filterType: 'text' },
        // --- normal columns
        { field: 'ordre.remarqueAbregee', header: this.translate.instant(this.commonTranslatePrefix + 'Remarque'), width: '266px', filterType: 'text', frozen: false },
        { field: 'ordre.designation', header: this.translate.instant(this.commonTranslatePrefix + 'Designation'), width: '266px', filterType: 'text', frozen: false },
        {
            field: 'ordre.dateDebutPlan', header: this.translate.instant(this.commonTranslatePrefix + 'DateDebutPlan'), width: '216px', filterType: 'calendar',
            type: 'date', format: this.dateFormat, frozen: false
        },
        {
            field: 'ordre.dateEngagement', header: this.translate.instant(this.commonTranslatePrefix + 'DateEngagement'), width: '156px', filterType: 'calendar',
            type: 'date', format: this.dateFormat, frozen: false
        },
        { field: 'assigneOGIV', header: this.translate.instant(this.commonTranslatePrefix + 'AssigneOGIV'), width: '216px', filterType: 'multi-select', frozen: false },
        { field: 'notes', header: this.translate.instant(this.commonTranslatePrefix + 'Notes'), width: '316px', filterType: 'multi-select', frozen: false },
        {
            field: 'contactTechnicien', header: this.translate.instant(this.commonTranslatePrefix + 'ContactTechnicien'), width: '216px', filterType: 'text',
            frozen: false
        },
        { field: 'ordre.nomClient', header: this.translate.instant(this.commonTranslatePrefix + 'NomClient'), width: '216px', filterType: 'text', frozen: false },
        {
            field: 'ordre.pieceJointeFlag', header: this.translate.instant(this.commonTranslatePrefix + 'PieceJointe'), width: '166px', filterType: 'noFilter',
            frozen: false
        },
        { field: 'wAB', header: this.translate.instant(this.commonTranslatePrefix + 'WAB'), width: '116px', filterType: 'noFilter', frozen: false },
        {
            field: 'ordre.typeEngagement', header: this.translate.instant(this.commonTranslatePrefix + 'TypeEngagement'), width: '341px', filterType: 'multi-select',
            frozen: false
        },
        { field: 'ordre.poste', header: this.translate.instant(this.commonTranslatePrefix + 'Poste'), width: '116px', filterType: 'text', frozen: false },
        { field: 'ordre.ligne', header: this.translate.instant(this.commonTranslatePrefix + 'Ligne'), width: '116px', filterType: 'text', frozen: false },
        {
            field: 'ordre.identificationTechnique', header: this.translate.instant(this.commonTranslatePrefix + 'IdentificationTechnique'), width: '116px',
            filterType: 'text', frozen: false
        },
        { field: 'ordre.commande', header: this.translate.instant(this.commonTranslatePrefix + 'Commande'), width: '166px', filterType: 'text', frozen: false },
        // --- addresses and operations columns
        { field: 'ordre.adresse', header: this.translate.instant(this.commonTranslatePrefix + 'Adresse'), width: '166px', filterType: 'text', frozen: false },
        { field: 'ordre.rue', header: this.translate.instant(this.commonTranslatePrefix + 'Rue'), width: '241px', filterType: 'text', frozen: false },
        { field: 'ordre.ville', header: this.translate.instant(this.commonTranslatePrefix + 'Ville'), width: '241px', filterType: 'text', frozen: false },
        { field: 'ordre.codePostal', header: this.translate.instant(this.commonTranslatePrefix + 'CodePostal'), width: '166px', filterType: 'text', frozen: false },
        { field: 'ordre.nombreOperations', header: '', width: '66px', frozen: false }, // invisible

        // MIV-768 : DCL - Retrait des colonnes Opération dans le tableau
        /*
        {
            field: 'ordre.operationCourante.numeroOperation', header: this.translate.instant(this.commonTranslatePrefix + 'Operation_Numero') +
                this.translate.instant(this.commonTranslatePrefix + 'Operation'), width: '141px', filterType: 'text', frozen: false
        },
        {
            field: 'ordre.operationCourante.statutSysteme', header: this.translate.instant(this.commonTranslatePrefix + 'Operation_StatutSysteme') +
                this.translate.instant(this.commonTranslatePrefix + 'Operation'), width: '166px', filterType: 'text', frozen: false
        },
        {
            field: 'ordre.operationCourante.workCenter', header: this.translate.instant(this.commonTranslatePrefix + 'Operation_WorkCenter') +
                this.translate.instant(this.commonTranslatePrefix + 'Operation'), width: '166px', filterType: 'multi-select', frozen: false
        },
        {
            field: 'ordre.operationCourante.clefReference', header: this.translate.instant(this.commonTranslatePrefix + 'Operation_ClefReference') +
                this.translate.instant(this.commonTranslatePrefix + 'Operation'), width: '166px', filterType: 'multi-select', frozen: false
        },
        {
            field: 'ordre.operationCourante.designation', header: this.translate.instant(this.commonTranslatePrefix + 'Operation_Designation') +
                this.translate.instant(this.commonTranslatePrefix + 'Operation'), width: '266px', filterType: 'text', frozen: false
        },
        {
            field: 'ordre.operationCourante.dateStatutLance', header: this.translate.instant(this.commonTranslatePrefix + 'Operation_DateStatutLance') +
                this.translate.instant(this.commonTranslatePrefix + 'Operation'), width: '216px', filterType: 'calendar', type: 'date', format: this.dateFormat,
            frozen: false
        },
        {
            field: 'ordre.operationCourante.statutUtilisateur', header: this.translate.instant(this.commonTranslatePrefix + 'Operation_StatutUtilisateur') +
                this.translate.instant(this.commonTranslatePrefix + 'Operation'), width: '216px', filterType: 'text', frozen: false
        },
        {
            field: 'ordre.operationCourante.matricule', header: this.translate.instant(this.commonTranslatePrefix + 'Operation_Matricule') +
                this.translate.instant(this.commonTranslatePrefix + 'Operation'), width: '216px', filterType: 'text', frozen: false
        },

        */

        /* Ne sont plus requise depuis DOGIV-4589
        { field: 'ordre.statutSysteme', header: this.translate.instant(this.commonTranslatePrefix + 'StatutSysteme'), width: '266px', filterType: 'text', frozen: false },
        { field: 'ordre.statutUtilisateur', header: this.translate.instant(this.commonTranslatePrefix + 'StatutUtilisateur'), width: '216px', filterType: 'text', frozen:
             false },
        { field: 'groupeEcart', header: this.translate.instant(this.commonTranslatePrefix + 'GroupeEcart'), width: '156px', filterType: 'multi-select', frozen: false },
        { field: 'ecartEngagement', header: this.translate.instant(this.commonTranslatePrefix + 'EcartEngagement'), width: '151px', filterType: 'text', frozen: false },
        { field: 'ordre.priorite', header: this.translate.instant(this.commonTranslatePrefix + 'Priorite'), width: '166px', filterType: 'text', frozen: false },
        { field: 'ordre.lot', header: this.translate.instant(this.commonTranslatePrefix + 'Lot'), width: '216px', filterType: 'text', frozen: false },
        { field: 'ordre.typeOrdre', header: this.translate.instant(this.commonTranslatePrefix + 'TypeOrdre'), width: '141px', filterType: 'text', frozen: false },
        { field: 'Ordre.Division', header: this.translate.instant(this.commonTranslatePrefix + 'DivLocal'), width: '141px', filterType: 'text', frozen: false },*/
    ];

    @ViewChild('demandeClientDatatable') demandeClientDatatable: Table;
    @ViewChild('globalFilter') globalFilter: ElementRef;

    public demandesClient$: Observable<DemandeClient[]> = this.store.pipe(
        select(getAllDemandesClient),
    );

    public isDemandesClientLoading$: Observable<boolean> = this.store.pipe(
        select(getDemandesClientLoading),
    );

    public isDomaineDeValeurLoading$: Observable<boolean> = this.store.pipe(
        select(getDomaineDeValeurLoading),
    );

    public noteSelectOptions$: Observable<SelectItem[] | null> = this.store.pipe(
        select(getNoteDeVisiteOptions)
    );

    constructor(
        private readonly store: Store,
        private translate: TranslateService,
        private configService: AppConfiguration,
        public dialog: MatDialog,
        public remarqueDialog: MatDialog,
        private changeDetectorRef: ChangeDetectorRef,
    ) {
        this.loadData();
        if (Object.keys(this.filtersEntries).length > 0) {
            Object.keys(this.filtersEntries).forEach((key) => {
                const value = this.filtersEntries[key];
                if ((Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value !== '')) {
                    this.filtersApply = true;
                }
            });
        } else {
            this.filtersApply = false;
        }

        this.cols.forEach((col: TableColumns) => this.globalFilterFields.push(col.field));
    }

    ngOnInit(): void {
        this.operationsPanelEvent = null;
        // this.scrollableCols = this.cols.filter((col) => !col.frozen);

        // this.frozenWidth = this.cols.filter((col) => col.frozen === true)
        //     .map((col) => parseInt((col.width ?? '0').replace('px', ''), 10))
        //     .reduce((a, b) => a + b, 0)
        //     .toString() + 'px';

        this.initTableFilters();
        this.loadDomaineDeValeur();

        this.subscriptions.push(
            this.updateDomaineDeValeur(),
            this.updateCurrentData(),
        );
    }

    public ngAfterViewInit(): void {
        this.registerCustomFilters();
        this.applyStorageFilters();
        this.changeDetectorRef.detectChanges();
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    public closeOverlayPanel(): void {
        this.operationsPanelEvent = null;
    }

    public openDemandeClient(demandeClient: DemandeClient): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.hasBackdrop = true;
        dialogConfig.data = demandeClient;
        dialogConfig.panelClass = 'full-overlay-container';
        dialogConfig.height = '100vh';
        const dialogRef = this.dialog.open(DemandesClientFormComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(() => {
            this.loadDomaineDeValeur();
        });
    }

    public openBatchUpdatesForm(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.hasBackdrop = true;
        dialogConfig.data = this.selectedDemandes;
        dialogConfig.height = '650px';
        const dialogRef = this.dialog.open(DemandesClientBatchUpdatesComponent, dialogConfig);

        dialogRef.afterClosed().subscribe((batchUpdateResults: DemandesClientBatchUpdatesResults) => {
            this.keepFailedUpdateSelected(batchUpdateResults);
        });
    }

    public inputGlobalFilter(value: Event): void {
        this.globalSearch((value.target as HTMLInputElement).value);
    }

    public globalSearch(value: string): void {
        this.eraseFilterButtonStatus(value, 'filterGlobal');

        this.demandeClientDatatable.filterGlobal(value, 'contains');

        this.filtersApply = (Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value !== '');
    }

    public eraseFilterButtonStatus(value: string, field: string): void {
        const index = this.currentFilters.findIndex((activeFilter: string) => activeFilter === field);
        if (index === -1 && (value !== '' || Array.isArray(value) && value.length > 0)) {
            this.currentFilters.push(field);
        } else if (index !== -1 && (value === '' || Array.isArray(value) && value.length === 0)) {
            this.currentFilters.splice(this.currentFilters.findIndex((activeFilter: string) => activeFilter === field), 1);
        }

        this.filtersApply = this.currentFilters.length > 0;
    }

    public clearAllFilters(): void {
        Object.keys(this.filtersEntries).forEach((key) => {
            const field = this.cols.find((col: TableColumns) => col.field === key);
            if (field) {
                let value: any;
                switch (field.filterType) {
                    case 'multi-select': value = []; break;
                    default: value = ''; break;
                }
                this.filtersEntries[key] = value;
                this.onFilterChange(value, key);
            }
        });

        if (this.globalFilter.nativeElement.value !== '') {
            this.globalFilter.nativeElement.value = '';
            this.demandeClientDatatable.filterGlobal('', 'contains');
        }

        this.filtersEntries = {};
        localStorage.setItem('DemandeClientTableFilters', JSON.stringify(this.filtersEntries));

        this.filtersApply = false;
    }

    private keepFailedUpdateSelected(batchUpdateResults: DemandesClientBatchUpdatesResults): void {
        if (batchUpdateResults && batchUpdateResults.failedUpdates) {
            const tempSelectedDemandes: DemandeClient[] = [];

            batchUpdateResults.failedUpdates.forEach(failedUpdate => {
                const foundedDemande = this.selectedDemandes.find(demande => demande.ordre.numeroOrdre === failedUpdate.numeroOrdre.toString());
                if (foundedDemande) {
                    tempSelectedDemandes.push(foundedDemande);
                }

            });
            this.selectedDemandes = tempSelectedDemandes;
        }
    }

    public openSapPiecesJointes(numeroOrdre: Readonly<string>): void {
        const url = `${this.configService.sapConfig.url}?%7etransaction=*ZAGIL%20G_AUFNR=${numeroOrdre}`;
        window.open(url, 'sapWebGui');
    }

    public openWAB(numeroOrdre: Readonly<string>): void {
        const layerName = getLayerValue(this.configService.wabConfig, 'IOG_DEMANDE_CLIENT');

        const url = `${this.configService.wabConfig.url}?query=${layerName},NumeroOrdre,${numeroOrdre}&level=18`;
        window.open(url, 'OgivWeb');
    }

    public showRemarque(numeroOrdre: Readonly<string>): void {
        const currentDemande = this.currentData.find((demande: DemandeClient) => demande.ordre.numeroOrdre === numeroOrdre);

        this.remarqueDialog.open(DemandesClientRemarqueDialogComponent, {
            width: '95%',
            maxWidth: '575px',
            maxHeight: '90%',
            autoFocus: false,
            data: {
                demandeClient: currentDemande,
                isEditing: false,
            } as DemandeClientRemarqueDialogDataModel
        });
    }

    public callOperations(numeroOrdre: Readonly<string>, event: any): void {
        this.currentDemande = this.currentData.find((demande: DemandeClient) => demande.ordre.numeroOrdre === numeroOrdre);
        this.operationsPanelEvent = event;
        this.store.dispatch(new LoadDemandeClientById(numeroOrdre));
    }

    private updateDomaineDeValeur(): Subscription {
        return this.noteSelectOptions$.pipe(
            tap((notes: SelectItem[] | null) => {
                if (notes) {
                    this.tableFiltersItems['notes'] = [];
                    notes.forEach((note: SelectItem) => {
                        this.tableFiltersItems['notes'].push({
                            name: note.value,
                            code: note.value
                        });
                    });
                    this.addEmptyValueToOneFilter('notes');
                }
            })
        ).subscribe();
    }

    private updateCurrentData(): Subscription {
        return this.demandesClient$.pipe(
            tap((demandesClient: DemandeClient[]) => {
                this.currentData = demandesClient.map((demande: DemandeClient) => this.formatObjectToList(cloneDeep(demande)));
                this.addEmptyValueToOneFilter('Pole');
                this.addEmptyValueToOneFilter('Site');
                this.sortAutogenerateFilters();
                if (this.operationsPanelEvent) {
                    this.displayOperations();
                }
            })
        ).subscribe();
    }

    private displayOperations(): void {
        const operationDialogRef = this.dialog.open(DemandesClientOperationComponent, {
            data: {
                commonTranslatePrefix: this.commonTranslatePrefix,
                currentDemande: this.currentDemande,
            } as DemandesClientOperationData,
            minWidth: 'fit-content',
            width: '1050px',
            maxWidth: '100%',
            autoFocus: false,
        });

        operationDialogRef.afterClosed().subscribe(() => {
            this.operationsPanelEvent = null;
        });
    }

    private formatObjectToList(demande: DemandeClient): any {

        if (demande.ordre.operations != undefined) {
            demande.ordre.operations = demande.ordre.operations.sort((n1, n2) => {
                if (n1.numeroOperation > n2.numeroOperation) {
                    return 1;
                }

                if (n1.numeroOperation < n2.numeroOperation) {
                    return -1;
                }
                return 0;
            });

        }

        demande.ordre.indexOperation = this.selectCurrentOperation(demande);
        if (demande.ordre.operations != undefined) {
            demande.ordre.operationCourante = demande.ordre.operations[demande.ordre.indexOperation];
        }

        this.setAutoGenerateFilters(demande);

        const derniereRemarque = DemandesClientHelper.extractDerniereRemarque(demande.ordre.remarque);
        const textRemarque = DemandesClientHelper.extractTextRemarque(derniereRemarque);
        demande.ordre.remarqueAbregee = DemandesClientHelper.getRemarqueAbregee(textRemarque);

        this.changeNullToEmptyString(demande);

        return demande;
    }

    private setAutoGenerateFilters(demande: DemandeClient): void {
        this.addFilterValueIfNotExist('pole', demande.pole);
        this.addFilterValueIfNotExist('site', demande.site);
        if (demande.ordre.operationCourante) {
            this.addFilterValueIfNotExist('ordre.operationCourante.clefReference', demande.ordre.operationCourante.clefReference);
        }
    }

    private addFilterValueIfNotExist(filterName: string, value: string | null): void {
        if (!this.tableFiltersItems[filterName]) {
            this.tableFiltersItems[filterName] = [];
        }

        if (value !== null && this.tableFiltersItems[filterName].findIndex((filter: any) => filter.code === value) === -1) {
            const name = value !== '' ? value : '- Vide -';

            this.tableFiltersItems[filterName].push({
                name: name,
                code: value,
            });
        }
    }

    private sortAutogenerateFilters(): void {
        if (this.tableFiltersItems.pole) {
            this.tableFiltersItems.pole = this.sortFilters(this.tableFiltersItems.pole);
        }
        if (this.tableFiltersItems.site) {
            this.tableFiltersItems.site = this.sortFilters(this.tableFiltersItems.site);
        }

        if (this.tableFiltersItems.clefReference) {
            this.tableFiltersItems.clefReference = this.sortFilters(this.tableFiltersItems.clefReference);
        }
    }

    private sortFilters(filters: any[]): any[] {
        return filters.sort((n1, n2) => {
            return (n1.name).localeCompare(n2.name);
        });
    }

    private changeNullToEmptyString(demande: DemandeClient): void {
        // Il est requis de filtrer certaines colonnes sur les valeurs null. Pour les données concernées, transformer les null en string vide ""
        if (demande.pole === null) {
            demande.pole = '';
        }
        if (demande.site === null) {
            demande.site = '';
        }
        if (demande.assigneOGIV === null) {
            demande.assigneOGIV = '';
        }
        if (demande.notes === null) {
            demande.notes = '';
        }
        if (demande.groupeEcart === null) {
            demande.groupeEcart = '';
        }
        if (demande.ordre.typeEngagement === null) {
            demande.ordre.typeEngagement = '';
        }
        if (demande.ordre.operationCourante?.workCenter === null) {
            demande.ordre.operationCourante.workCenter = '';
        }
    }

    private selectCurrentOperation(demande: DemandeClient): number {
        let index = -1;

        if (demande.ordre.nombreOperations > 1) {
            index = demande.ordre.operations.findIndex((operation: Operation) => operation.statutSysteme.includes('CONF') === false);
            if (index === -1) {
                index = 0;
            }
        } else if (demande.ordre.nombreOperations === 1) {
            index = 0;
        }
        return index;
    }

    private loadData(): void {
        this.loadTableFiltersFromStorage();
        this.store.dispatch(new DemandesClientSearch([]));
    }

    private loadDomaineDeValeur(): void {
        this.store.dispatch(new LoadDomaineDeValeur(this.domaineDeValeurConcern, this.domaineDeValeurType));
    }

    private loadTableFiltersFromStorage(): void {
        this.filtersEntries = JSON.parse(localStorage.getItem('DemandeClientTableFilters') || '{}');
        Object.keys(this.filtersEntries).forEach((key) => {
            if (this.filtersEntries[key] !== null && key.toLowerCase().includes('date')) {
                this.filtersEntries[key] = moment.utc(this.filtersEntries[key]).toDate();
            }
        });
    }

    public getNestedValue(rowData: DemandeClient, propertyName: string): any {
        if (rowData) {
            const properties = propertyName.split('.');
            if (properties.length > 1) {
                const property = properties.shift() || '';
                return this.getNestedValue(rowData[property], properties.join('.'));
            } else {
                return rowData[properties[0]];
            }
        }
    }

    private applyStorageFilters(): void {
        Object.keys(this.filtersEntries).forEach((key) => {
            this.onFilterChange(this.filtersEntries[key], key);
        });
    }

    private registerCustomFilters(): void {
        this.demandeClientDatatable.filterService.register('empty', (value: any): boolean => {
            if (value === undefined || value === null || value === '') {
                return true;
            }

            return false;
        });

        this.demandeClientDatatable.filterService.register('notEmpty', (value: any): boolean => {
            if (value === undefined || value === null || value === '') {
                return false;
            }

            return true;
        });
    }

    private initTableFilters(): void {
        this.addEnumToFilters(this.tableFiltersItems, 'statutOGIV', StatutOgivEnum);
        this.addEnumToFilters(this.tableFiltersItems, 'assigneOGIV', AssigneOGIVEnum);
        this.addEnumToFilters(this.tableFiltersItems, 'groupeEcart', GroupeEcartEnum);
        this.addEnumToFilters(this.tableFiltersItems, 'ordre.typeEngagement', TypeEngagementEnum, this.commonTranslatePrefix + 'TypeEngagementEnum.');
        this.addEnumToFilters(this.tableFiltersItems, 'ordre.operationCourante.workCenter', WorkCenterEnum);
        this.addEmptyValueToOneFilter('assigneOGIV');
        this.addEmptyValueToOneFilter('groupeEcart');
        this.addEmptyValueToOneFilter('ordre.typeEngagement');
        this.addEmptyValueToOneFilter('ordre.operationCourante.workCenter');
    }

    private addEnumToFilters(tableFiltersItems: any, filterName: string, enumeration: any, translatePrefixe: string = ''): void {
        tableFiltersItems[filterName] = Object.values(enumeration).map((value: any) => {
            return {
                name: translatePrefixe.length > 0 ? this.translate.instant(translatePrefixe + value) : value,
                code: value,
            };
        });
    }

    private addEmptyValueToOneFilter(filterName: string): void {
        if (this.tableFiltersItems[filterName]?.findIndex((filter: any) => filter.code === '') === -1) {
            this.tableFiltersItems[filterName].unshift({
                name: '- Vide -',
                code: '',
            });
        }
    }

    public onFilterChange(value: any, fieldName: string): void {
        const field = this.cols.find((col: TableColumns) => col.field === fieldName);
        if (field) {
            switch (field.filterType) {
                case 'multi-select': {
                    const values = value.map((selectedValue: any) => selectedValue.code);
                    this.applyFilters(values, fieldName, 'in');
                    break;
                }
                case 'calendar': {
                    const date = moment(value, 'YYYY/MM/DD', true);
                    if (date.isValid()) {
                        this.applyFilters(date.toDate(), fieldName, 'equals');
                    }
                    if (value === '') {
                        this.applyFilters(value, fieldName, 'equals');
                    }
                    break;
                }
                default: {
                    let newValue = value;
                    if (typeof value !== 'string') {
                        newValue = (value.target as HTMLInputElement).value;
                    }
                    if (newValue === '!') {
                        this.applyFilters(newValue, fieldName, 'empty');
                    } else if (newValue === '*') {
                        this.applyFilters(newValue, fieldName, 'notEmpty');
                    } else {
                        this.applyFilters(newValue, fieldName, 'contains');
                    }
                    break;
                }
            }
        }
    }

    private applyFilters(value: any, fieldName: string, matchMode: any): void {
        this.eraseFilterButtonStatus(value, fieldName);
        this.demandeClientDatatable.filter(value, fieldName, matchMode);
        localStorage.setItem('DemandeClientTableFilters', JSON.stringify(this.filtersEntries));
    }

    public getEmptyMessage(): string {
        return this.translate.instant(this.translatePrefix + 'noData');
    }

    public reload(): void {
        this.selectedDemandes = [];
        this.store.dispatch(new DemandesClientSearch([]));
    }
}
